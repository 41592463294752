<template>
  <el-scrollbar :height="clientHeight-30+'px'">
    
    <el-row :gutter="10" style="width: 100%; margin-top: 5px">
      <el-col  :xs="24" :sm="8" :md="8" :lg="8" :xl="8"  class="report_item">
        <div id="brandReport" style="width: 100%; height: 400px"></div>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"  class="report_item">
        <div id="serviceReport" style="width: 100%; height: 100%"></div>
      </el-col>

      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"  class="report_item">
        <div id="fittingsReport" style="width: 100%; height: 100%"></div>
      </el-col>
    
      <!-- <el-col :span="12"> 
    
        小米店,维修单100单,营业额8000,返修10台
    
    </el-col>
      <el-col :span="12"> 
        华为店,维修单100单,营业额8000,返修10台
    </el-col>
     -->
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8" class="report_item">
        <div id="month30" style="width: 100%; height: 260px"></div>
      </el-col>

      <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="8"  class="report_item">
        <div id="amount30" style="width: 100%; height: 260px"></div>
      </el-col>
 
      <el-col  :xs="24" :sm="12" :md="12" :lg="24" :xl="8"  class="report_item">
        <div id="thisMonth" style="width: 100%; height: 260px"></div>
      </el-col>
    </el-row>
</el-scrollbar>
</template>
      <script>
import axios from "../../commons/AxiosMethod.js";
import navigation from "../../components/Modules/CompanyNavigation.vue";
import { ElMessageBox, ElMessage, UploadProps } from "element-plus";
import { CloseBold, Plus, Delete } from "@element-plus/icons-vue";
import * as echarts from "echarts";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      BusinessVolume: {
        OrderTotal: "",
        PartOrderTotal: "",
        DayOrderTotal: "",
      },
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      BusinessVolumeWhere: {},
      OrderCount: {},
      TrendBusinessVolumeWhere: {},
      TrendBusinessVolume: {},
      UserInfo: {},

      headersImage: {},
      UploadPictureImageUrl: "",
    };
  },
  name: "App",
  mounted() {
    this.GetRepairsThisMonthReport();
    this.GetRepairsQuantityReportByInterval();
    this.GetRepairsAmountReportBy30();
    this.GetRepairsBrandReport();
    this.GetRepairsServiceReport();
    this.GetRepairsFittingsReport();

    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.UploadFullImageFileUrl =
      axios.GetUrl() + "/api/Upload/FullImageFileWebp";

    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };
  },

  components: {
    navigation,
    Plus,
  },
  methods: {
    //
    //获取维修服务饼图
    GetRepairsFittingsReport() {
      axios.apiMethod(
        "/report/Company/GetRepairsFittingsReport",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("fittingsReport");
          var myChart = echarts.init(chartDom, "dark");

          var option = {
            title: {
              //   text: "维修品牌",
              subtext: "30天使用配件",
              left: "center",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              orient: "vertical",
              left: "left",
            },
            series: result.Data.series,
          };
          myChart.setOption(option);
        }
      );
    },

    //获取维修服务饼图
    GetRepairsServiceReport() {
      axios.apiMethod(
        "/report/Company/GetRepairsServiceReport",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("serviceReport");
          var myChart = echarts.init(chartDom, "dark");

          var option = {
            title: {
              //   text: "维修品牌",
              subtext: "30天维修服务",
              left: "center",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              orient: "vertical",
              left: "left",
            },
            series: result.Data.series,
          };
          myChart.setOption(option);
        }
      );
    },
    //获取维修品牌饼图
    GetRepairsBrandReport() {
      axios.apiMethod(
        "/report/Company/GetRepairsBrandReport",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("brandReport");
          var myChart = echarts.init(chartDom, "dark");

          var option = {
            title: {
              //   text: "维修品牌",
              subtext: "30天维修品牌",
              left: "center",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              orient: "vertical",
              left: "left",
            },
            series: result.Data.series,
          };
          myChart.setOption(option);
        }
      );
    },
    //获取近30天的营业金额
    GetRepairsAmountReportBy30() {
      axios.apiMethod(
        "/report/Company/GetRepairsAmountReportBy30",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("amount30");
          var myChart = echarts.init(chartDom, "dark");

          var option = {
            title: {
              text: "30天营业额", // 主标题文本
              // subtext: '维修单数量', // 副标题文本
              left: "left", // 标题水平居中
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: "category",
              data: result.Data.data,
            },
            yAxis: {
              type: "value",
            },
            series: result.Data.series,
          };

          //   for (var i = 0; i < result.Data.Items.length; i++) {
          //     option.series.push({
          //       data: result.Data.Items[i].SeriesDataItems,
          //       type: "line",
          //       name: result.Data.Items[i].StoreName,
          //     });
          //   }

          myChart.setOption(option);
        }
      );
    },
    //获取近30天的维修数量
    GetRepairsQuantityReportByInterval() {
      axios.apiMethod(
        "/report/Company/GetRepairsQuantityReportByInterval",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("month30");
          var myChart = echarts.init(chartDom, "dark");

          var option = {
            title: {
              text: "30天维修单数量", // 主标题文本
              // subtext: '维修单数量', // 副标题文本
              left: "left", // 标题水平居中
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: "category",
              data: result.Data.data,
            },
            yAxis: {
              type: "value",
            },
            series: [],
          };

          for (var i = 0; i < result.Data.Items.length; i++) {
            option.series.push({
              data: result.Data.Items[i].SeriesDataItems,
              type: "line",
              name: result.Data.Items[i].StoreName,
            });
          }

          myChart.setOption(option);
        }
      );
    },
    //获取本月订单量
    GetRepairsThisMonthReport() {
      axios.apiMethod(
        "/report/Company/GetRepairsThisMonthReport",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("thisMonth");
          var myChart = echarts.init(chartDom, "dark");

          var option = {
            title: {
              text: "本月维修单数量", // 主标题文本
              // subtext: '维修单数量', // 副标题文本
              left: "left", // 标题水平居中
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: "category",
              data: result.Data.data,
            },
            yAxis: {
              type: "value",
            },
            series: [],
          };

          for (var i = 0; i < result.Data.Items.length; i++) {
            option.series.push({
              data: result.Data.Items[i].SeriesDataItems,
              type: "line",
              name: result.Data.Items[i].StoreName,
            });
          }

          myChart.setOption(option);
        }
      );
    },
    GetMyUserInfo() {
      axios.apiMethod("/stores/User/GetMyUserInfo", "get", {}, (result) => {
        this.UserInfo = result.Data;
      });
    },
    UploadProductSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.UserInfo.ProfilePhotoFormat = response.Data.ImageFormet;
        this.UserInfo.ProfilePhoto = response.Data.FileName;
      }
    },

    // 修改个人信息
    UpdateUserInfo() {
      axios.apiMethod(
        "/stores/User/UpdateUserInfo",
        "post",
        this.UserInfo,
        (result) => {
          if (result.Data.IsSuccess) {
            ElMessage({
              title: "提示",
              message: "操作成功",
              type: "success",
            });
          }
        }
      );
    },
  },
};
</script>
      
      <style scoped>
.flex-grow {
  flex-grow: 1;
}

.avatar-uploader .avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
  border: 1px dashed #666;
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  text-align: center;
}
.report_item{
    margin-top: 10px;
}
</style>
      