<template>
  <div
    style="display: flex; width: 100%;max-width: 2200px;margin: 0 auto; ;"
    :style="{ height: ContentHeight - 10 + 'px' }"
  >
    <div class="mang_menu" :style="{ width: Display ? '200px' : '' }">
      <div class="menu_title" index="0">
        <b style="font-size: 16pt; margin: 0px 15px" v-show="Display"
          >VisionTec
        </b>
        <el-icon class="menu_item_icon" v-show="Display" @click="DisplayHide"
          ><Fold
        /></el-icon>
        <el-icon class="menu_item_icon" v-show="!Display" @click="DisplayShow"
          ><Expand
        /></el-icon>
      </div>

      <el-scrollbar style="height: calc(100% - 240px)">
        
     
        <div
          class="menu_item"
          @click="skip('compantCollectReport')"
          :class="NavIndex == 'compantCollectReport' ? 'optColor' : 'notColor'"
        >
          <el-tooltip
            v-if="!Display"
            class="box-item"
            effect="dark"
            :content="tools.GetLanguageValue('web.汇总报表')"
            placement="left-end"
          >
            <el-icon class="menu_item_icon" v-show="!Display"><Box /></el-icon>
          </el-tooltip>
          <el-icon v-show="Display" class="menu_item_icon"><Box /></el-icon>
          <div v-show="Display">
            <div class="el_menu_item">
              <el-link type="info" :underline="false">
                {{ tools.GetLanguageValue("web.汇总报表") }}</el-link
              >
            </div>
          </div>
        </div> 
      
        <div
          class="menu_item"
          @click="skip('companyRepairsQuantityReport')"
          :class="NavIndex == 'storagesManage' ? 'optColor' : 'notColor'"
        >
          <el-tooltip
            v-if="!Display"
            class="box-item"
            effect="dark"
            :content="tools.GetLanguageValue('web.订单报表')"
            placement="left-end"
          >
            <el-icon class="menu_item_icon" v-show="!Display"><Box /></el-icon>
          </el-tooltip>
          <el-icon v-show="Display" class="menu_item_icon"><Box /></el-icon>
          <div v-show="Display">
            <div class="el_menu_item">
              <el-link type="info" :underline="false">
                {{ tools.GetLanguageValue("web.订单报表") }}</el-link
              >
            </div>
          </div>
        </div>
     
        <div
          class="menu_item"
          @click="skip('clients')"
          :class="NavIndex == 'clientManage' ? 'optColor' : 'notColor'"
        >
          <el-tooltip
            v-if="!Display"
            class="box-item"
            effect="dark"
           :content="tools.GetLanguageValue('web.营业额报表')"
            placement="left-end"
          >
            <el-icon class="menu_item_icon" v-show="!Display"
              ><UserFilled
            /></el-icon>
          </el-tooltip>
          <el-icon v-show="Display" class="menu_item_icon"
            ><UserFilled
          /></el-icon>
          <div v-show="Display">
            <div class="el_menu_item">
              <el-link type="info" :underline="false">
                {{ tools.GetLanguageValue("web.营业额报表") }}</el-link
              >
            </div>
          </div>
        </div>
        <div
          index="settingsManage"
          class="menu_item"
          @click="skip('settings')"
          :class="NavIndex == 'settingsManage' ? 'optColor' : 'notColor'"
        >
          <el-tooltip
            v-if="!Display"
            class="box-item"
            effect="dark"
         :content="tools.GetLanguageValue('web.销售报表')"
            placement="left-end"
          >
            <el-icon class="menu_item_icon" v-show="!Display"
              ><Setting
            /></el-icon>
          </el-tooltip>
          <el-icon v-show="Display" class="menu_item_icon"><Setting /></el-icon>
          <div v-show="Display">
            <div class="el_menu_item">
              <el-link type="info" :underline="false">
                {{ tools.GetLanguageValue("web.销售报表") }}</el-link
              >
            </div>
          </div>
        </div>

        <div
          index="settingsManage"
          class="menu_item"
          @click="skip('settings')"
          :class="NavIndex == 'settingsManage' ? 'optColor' : 'notColor'"
        >
          <el-tooltip
            v-if="!Display"
            class="box-item"
            effect="dark"
         :content="tools.GetLanguageValue('web.表格化报表')"
            placement="left-end"
          >
            <el-icon class="menu_item_icon" v-show="!Display"
              ><Setting
            /></el-icon>
          </el-tooltip>
          <el-icon v-show="Display" class="menu_item_icon"><Setting /></el-icon>
          <div v-show="Display">
            <div class="el_menu_item">
              <el-link type="info" :underline="false">
                {{ tools.GetLanguageValue("web.表格化报表") }}</el-link
              >
            </div>
          </div>
        </div>

        <div
          index="settingsManage"
          class="menu_item"
          @click="skip('settings')"
          :class="NavIndex == 'settingsManage' ? 'optColor' : 'notColor'"
        >
          <el-tooltip
            v-if="!Display"
            class="box-item"
            effect="dark"
         :content="tools.GetLanguageValue('web.店铺管理')"
            placement="left-end"
          >
            <el-icon class="menu_item_icon" v-show="!Display"
              ><Setting
            /></el-icon>
          </el-tooltip>
          <el-icon v-show="Display" class="menu_item_icon"><Setting /></el-icon>
          <div v-show="Display">
            <div class="el_menu_item">
              <el-link type="info" :underline="false">
                {{ tools.GetLanguageValue("web.店铺管理") }}</el-link
              >
            </div>
          </div>
        </div>


      </el-scrollbar>

      <div style="height: 170px; background-color: #626262; padding: 5px">
        <p style="text-align: center">
          {{ UserInfo.Name }}
        </p>
        <div style="width: 100%; text-align: center; margin-top: 15px">
          <el-popover placement="top-start" :width="220" trigger="click">
            <template #reference>
              <el-image
                style="width: 40px; height: 40px"
                :src="LanguageOpt.ImageFormat"
                v-if="!Display"
              >
              </el-image>

              <div style="width: 100%; text-align: center">
                <div
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 0 auto;
                    width: calc(100% - 30px);
                    height: 40px;
                  "
                >
                  <el-image
                    style="width: 40px; height: 40px"
                    :src="LanguageOpt.ImageFormat"
                  >
                  </el-image>
                  <div style="margin-left: 22px">
                    {{ LanguageOpt.Name }}
                  </div>
                </div>
              </div>
            </template>
            <div style="height: 40px">
              <el-select
                v-model="LanguageId"
                :placeholder="tools.GetLanguageValue('web.请选择语言')"
                style="margin: 0px"
                @change="CheckLanguage()"
              >
                <el-option
                  v-for="item in Languages"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: flex-start;
                      justify-items: center;
                      align-items: center;
                      border-top: 1px solid #eee;
                      margin-top: 4px;
                      gap: 15px;
                    "
                  >
                    <img
                      style="width: 20px; height: 20px"
                      :src="item.ImageFormat"
                    />
                    <span>{{ item.Name }}</span>
                  </div>
                </el-option>
              </el-select>
            </div>
          </el-popover>
        </div>

        <div style="width: 100%; text-align: center; margin-top: 15px">
          <el-button
            v-show="Display"
            type="danger"
            @click="Logout"
            style="width: calc(100% - 30px); margin: 0px; height: 40px"
          >
            <el-icon style="font-size: 15pt"><SwitchButton /></el-icon>
          </el-button>

          <el-button
            v-show="!Display"
            circle
            type="danger"
            @click="Logout"
            style="width: 35px; margin: 0px; height: 35px"
          >
            <el-icon style="font-size: 15pt"><SwitchButton /></el-icon>
          </el-button>
        </div>
      </div>
    </div>
    <div class="mang_content" :style="{ width: ContentWidth + 'px' }">
      <div style="height: 60px;background-color: #545c64;"></div>
      <slot></slot>
    </div>
  </div>
</template>
 
<script>
import { ElMessageBox } from "element-plus";
import axios from "../../commons/AxiosMethod.js";
import tools from "../../commons/tools.js";
import {
  Search,
  PieChart,TrendCharts,
  Fold,
  Expand,
  Setting,
  User,
  Refresh,
  Cpu,
  Goods,View,
  UserFilled,
  Box,
  List,
  DocumentCopy,
  Apple,
  Help,
  Coin,
  SwitchButton,
  Ticket,
} from "@element-plus/icons-vue";

export default {
  data() {
    return {
      UserInfo: {},
      LanguageOpt: "",
      LanguageId: {},
      Languages: [
        {
          Image: "、、",
          Name: "中文",
          Id: "",
        },
      ], //语言列表
      Display: true,
      NavIndex: 0,
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      ContentWidth: document.documentElement.clientWidth - 150,
      ContentHeight: document.documentElement.clientHeight,
      tools: tools,
    };
  },
  name: "App",

  components: {
    PieChart,TrendCharts,
    Fold,
    Expand,
    Setting,
    User,
    Refresh,View,
    Cpu,
    tools,
    SwitchButton,
    Goods,
    List,
    DocumentCopy,
    Apple,
    Help,
    Coin,
    Ticket,
    UserFilled,
    Box,
  },
  props: {
    index: 0,
  },
  mounted() {
    this.LanguageId = localStorage.getItem("LanguageId");

    this.GetLanguageList();

    if (localStorage.getItem("DisplayType") == "DisplayShow") {
      this.DisplayShow();
    } else {
      this.DisplayHide();
    }

    this.NavIndex = this.index;
    var userInfo = localStorage.getItem("UserInfo");
    this.UserInfo = JSON.parse(userInfo);
  },
  watch: {
    //侦听Model
    Model(val, newval) {
      this.ProductModel = this.Model;
    },
  },

  methods: {
    onresize() {
      this.clientWidth = document.documentElement.clientWidth;
      this.clientHeight = document.documentElement.clientHeight;

      if (this.Display) {
        this.ContentWidth = this.clientWidth - 220;
      } else {
        this.ContentWidth = this.clientWidth - 50;
      }
      this.ContentHeight = document.documentElement.clientHeight;
    },

    //注销登录
    Logout() {
      ElMessageBox.confirm("确定要注销登录吗？", "提示", {
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          localStorage.setItem("Authentication_Header", "");
          this.$router.push({
            path: "/",
            query: {
              s: Date.now(),
            },
          });
        })
        .catch(() => {});
    },
    //选择语言
    CheckLanguage() {
      localStorage.setItem("LanguageId", this.LanguageId);
      this.Languages.forEach((element) => {
        if (element.Id == this.LanguageId) {
          this.LanguageOpt = element;
          tools.GetLanguageById(element.Id, () => {
            window.location.href = window.location.href;
          });
        }
      });
      this.GetLanguageList();
    },
    //语言的列表
    GetLanguageList() {
      const param = {};
      axios.apiMethod(
        "/all/Language/GetLanguageList",
        "get",
        param,
        (result) => {
          this.Languages = result.Data;
          this.Languages.forEach((element) => {
            if (element.Id == this.LanguageId) {
              this.LanguageOpt = element;
              tools.GetLanguageById(element.Id);
            }
          });

          if (
            this.LanguageId == "" ||
            this.LanguageId == null ||
            this.LanguageId == undefined
          ) {
            this.Languages.forEach((element) => {
              if (element.Default) {
                this.LanguageOpt = element;
                localStorage.setItem("LanguageId", element.Id);
                tools.GetLanguageById(element.Id);
              }
            });
          }

        }
      );
    },

    DisplayHide() {
      this.Display = false;
      this.ContentWidth = this.clientWidth - 50;
      localStorage.setItem("DisplayType", "DisplayHide");
    },
    DisplayShow() {
      this.Display = true;
      this.ContentWidth = this.clientWidth - 150;
      localStorage.setItem("DisplayType", "DisplayShow");
    },
    resize() {
    
    },
    skip(url) {
     
      this.$router.push({
        path: url,
        query: {
          s: Date.now(),
        },
      });
    },
  },
};
</script>
  
<style scoped lang="less">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.mang_content {
  width: calc(100% - 3px);
  margin: 0px  5px ;
}


.mang_menu {
  height: 100%;

  background-color: #545c64;
  color: #fff;

  .menu_title {
    height: 50px;
    line-height: 50px;
    margin: 5px;
    display: flex;
    justify-content: space-between;

    .menu_item_icon {
      width: 50px;
      height: 50px;
      font-size: 19pt;
      line-height: 50px;
      color: #f39801;
    }
  }
  .menu_item {
    height: 50px;
    line-height: 50px;
    display: flex;
    margin: 5px;

    .menu_item_icon {
      width: 50px;
      height: 50px;
      font-size: 16pt;
      line-height: 50px;
    }
    .el_menu_item {
      width: 140px;
    }
  }
}

.optColor {
  color: #e6a23c;
  .menu_item_icon {
    color: #e6a23c;
  }
  .el_menu_item {
    a {
      color: #e6a23c;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
}
.notColor {
  color: #fff;
  .menu_item_icon {
    color: #fff;
  }
  .el_menu_item {
    a {
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
}
</style>
 











